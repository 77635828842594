import {Component} from "react";
import Container from "../../ui/Container";
import Wizard from "../../ui/Wizard";
import Panel from "../../ui/Panel";
import Txt from "../../ui/Txt";
import ThreeModel from "../../presets/ThreeModel";
import {BoxGeometry, Euler, Mesh, MeshBasicMaterial} from "three";
import {Navigate} from "react-router-dom";

const questions = [
    {
        name: "activity_level",
        label:`Choose your activity level`,
        description: `To build a nutrition profile suitable to your lifestyle, we need to know how active you are.`,
        options: [
            {value: 1, label: "I spend most of the day sitting down"},
            {value: 2, label: "I spend most of the day on my feet"},
            {value: 3, label: "I do physical activities throughout the day"},
            {value: 4, label: "My job is intensively physical"},
        ]
    },
    {
        name: "trainings",
        label:`Are you going to exercise?`,
        description: `To build a nutrition profile suitable to your lifestyle, we need to know how often you are going to do physical exercise.`,
        options: [
            {value: 1, label: "Not at all"},
            {value: 2, label: "Once or twice a week"},
            {value: 3, label: "3 times a week"},
            {value: 4, label: "4 times a week"},
            {value: 5, label: "5 times a week"},
        ]
    },
    {
        label: `What are your personal stats?`,
        description: `Finally, we need your personal stats to fine-tune your personalized menu.`,
        fields : [
            {name: 'height', label: `Your Height (cm)`},
            {name: 'weight', label: `Your Weight (kg)`},
            {name: 'age', label: `Your Age`},
        ]
    }
]

export default class Quiz extends Component {

    constructor(props) {
        super(props);

        this.state = {
            submitted : false,
            animationSpeed: 3,
            animationStage: 0,
            answers : null,
            aScale: 1,
            aScale2: 1,
            aScale3: 1,
            doneAnim: false,
            nextTimeout: false,
            lastTimeout: false,
            rot: 0,
            mask:null,
        }
    }

    render() {
        return <div className={this.clsName}>
            <Container full={true} vCentered={true}>
                {this.state.submitted ?
                    <Panel raw={true} centered={true}>
                        <Txt title={true}>{this.state.doneAnim?'Nutrition Plan Created!':'Creating nutrition plan'}</Txt>
                        <Txt>{this.state.doneAnim?'Stand by, we are forwarding you to your plan overview...':'Calculating stats and building a personalized plan...'}</Txt>
                        <ThreeModel path="./loops.gltf" initScene={this.initScene} animate={this.animate} />
                        {this.state.animationStage>=3 && <Navigate to="/quiz/results" />}
                    </Panel> :
                    <Wizard questions={questions} onSubmit={this.onSubmit} />
                }
            </Container>
        </div>
    }

    onSubmit = (answers) => {
        this.setState({submitted : true, answers})
    }

    initScene = (scene, camera, renderer, lights) => {
        const [outer, inner, pill] = scene.children[0].children

        const {min, max} = pill.geometry.boundingBox
        const mask = new Mesh(new BoxGeometry(Math.abs(min.x)+max.x,Math.abs(min.y)+max.y,Math.abs(min.z)+max.z), new MeshBasicMaterial())
        mask.material.transparent = !0
        mask.material.opacity = 0
        mask.renderOrder = 0
        pill.renderOrder = 1
        scene.add(mask)
        this.setState({mask})
    }

    animate = (scene, camera, renderer, lights) => {
        const [outer, inner, pill] = scene.children[0].children
        let {rot, animationSpeed, animationStage, aScale, aScale2, aScale3, mask} = this.state
        pill.material.transparent = outer.material.transparent = true
        let opacity, eu
        const sin = Math.sin(rot)

        if (mask) mask.position.y = rot * 0.16

        rot += 0.01 * animationSpeed
        this.setState({rot})

        if (animationStage === 0) {
            eu = new Euler(0, rot * 0.5, 0)
            outer.setRotationFromEuler(eu)
            eu = new Euler(0, rot , 0)
            inner.setRotationFromEuler(eu)
            inner.material.opacity = Math.min(rot / 3, 1)

            opacity = Math.min(1, rot / 15 - 0.05)
            pill.material.opacity = Math.max(0, opacity)

            if (opacity >= 1 && animationStage < 1) {
                this.setState({animationStage : 1})
            }
            lights.forEach(light => light.intensity = 2.5 + sin * 0.9)
        }
        else if (animationStage === 1) {
            if (Math.abs(outer.rotation.y) > 0.03 || Math.abs(inner.rotation.y) > 0.03) {
                if (Math.abs(outer.rotation.y) > 0.03) {
                    let eu = new Euler(0, rot * 0.5, 0)
                    outer.setRotationFromEuler(eu)
                }
                if (Math.abs(inner.rotation.y) > 0.03) {
                    let eu = new Euler(0, rot, 0)
                    inner.setRotationFromEuler(eu)
                }
            }
            else {
                if (!this.state.nextTimeout) this.setState({nextTimeout : setTimeout(() => this.setState({animationStage: 2}), 300)})
            }
        }
        else if (animationStage === 2) {
            aScale = Math.min(1.1, aScale+0.003)
            aScale2 = Math.min(1.09, aScale2+0.0026)
            aScale3 = Math.min(1.03, aScale3+0.0015)
            outer.scale.set(aScale, aScale, aScale)
            inner.scale.set(aScale2, aScale2, aScale2)
            pill.scale.set(aScale3, aScale3, aScale3)

            const level = Math.min(1, 0.35 + rot / 8)
            lights.forEach(light => light.intensity = 3.5 *level + sin * 0.9)

            this.setState({aScale, aScale2, aScale3, doneAnim: true})

            if (!this.state.lastTimeout) this.setState({lastTimeout : setTimeout(() => this.setState({animationStage: 3}), 4000)})

        }
    }

    get clsName() {
        return 'quiz'
    }
}
