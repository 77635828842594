import {Component} from "react";
import Logo from "../../assets/images/logo.png"
export default class Navbar extends Component {

    render() {
        return <div className={this.clsName}>
            <img src={Logo} alt="Logo" className="logo" />
        </div>
    }

    get clsName() {
        return 'navbar'
    }

}
