import {Component} from "react";
import Container from "../ui/Container";
import Panel from "../ui/Panel";
import Txt from "../ui/Txt";
import Form from "../ui/Form";

const fields = [
    {name: "first_name", label: "First Name"},
    {name: "last_name", label: "Last Name"},
    {name: "email", label: "E-mail"},
]

const stripeLink = "https://buy.stripe.com/14kaHieJs0s99HO9AA";

export default class Signup extends Component {

    render() {
        return <Container vCentered={true}>
            <Panel raw={true} centered={true}>
                <Txt title={true}>Sign Up</Txt>
                <Txt>Get your personalized menu and nutrition plan for only $19.99</Txt>
            </Panel>
            <Panel centered={true}>
                <Form fields={fields} submitText="Sign Up" submit={this.onSubmit} centered={true} />
                <Txt>Already have an account? <Txt to="/login">Log In</Txt></Txt>
            </Panel>
        </Container>
    }

    onSubmit(data) {
        const {email} = data
        let url = stripeLink
        if (email) url += '?prefilled_email='+email
        console.log(url, data)
        window.location.href = url
    }

}
