import {Component} from "react";
export default class BoxGroup extends Component {

    render() {
        return <div className={this.clsName}>
            {this.props.children}
        </div>
    }

    get clsName() {
        let n = 'box-group'
        if (this.props.primary) n += ' primary'
        if (this.props.centered) n += ' centered'
        if (this.props.square) n += ' square'
        return n
    }

}
