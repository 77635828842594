import {Component} from "react";
import PropTypes from 'prop-types'
export default class Container extends Component {

    render() {
        return <div className={this.clsName}>
            {this.props.children}
        </div>
    }

    get clsName() {
        let n = 'container'
        if (this.props.main) n += ' main'
        if (this.props.vCentered) n += ' v-centered'
        if (this.props.size) n += ' size_'+this.props.size
        if (this.props.full) n += ' full'
        return n
    }

}

Container.propTypes = {
    main: PropTypes.bool,
    vCentered: PropTypes.bool,

    size: PropTypes.oneOf(['small','normal','big','title']),
    full: PropTypes.bool,
}
