import {Component} from "react";
export default class FormInput extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: ''
        }
        if (this.props.value) this.setState({value: this.props.value})
    }

    render() {
        return <input type={this.type} value={this.state.value} onChange={this.onChange} className={this.clsName} placeholder={this.placeholder} />
    }

    onChange = (e) => {
        this.setState({value: e.target.value})
        if (this.props.onChange) this.props.onChange(e.target.value)
    }

    get text() {
        if (typeof this.props.children !== 'object') return this.props.children
        return this.props.children.toString()
    }

    get placeholder() { return this.props.placeholder || ''}
    get type() { return this.props.type || 'text' }

    get clsName() {
        let n = 'form-input'
        return n
    }

}
