import {Component} from "react";
import Panel from "../ui/Panel";
import Txt from "../ui/Txt";
import Container from "../ui/Container";
import Image from "../ui/Image";

import image1 from '../../assets/images/loss-drawing.png'
import image2 from '../../assets/images/hip-drawing.png'
export default class Estimates extends Component {

    render() {
        return <Container>
                <Panel centered={true}>
                    <Txt big={true}>Calories</Txt>
                    <Txt title={true}>{this.props.calories}</Txt>
                    <Txt>Recommended daily calories intake for your plan</Txt>
                </Panel>
                <Panel centered={true}>
                    <Txt big={true}>Predicted Weight</Txt>
                    <Txt title={true}>{this.props.weight}kg</Txt>
                    <Txt>Estimated Weight by 3 months in the plan</Txt>
                    <Image img={image1} />
                </Panel>
                <Panel centered={true}>
                    <Txt big={true}>Hip Size</Txt>
                    <Txt title={true}>{this.props.hipSize}cm</Txt>
                    <Txt>Expected hip size reduction by 3 months in the plan</Txt>
                    <Image img={image2} />
                </Panel>
        </Container>
    }

    get clsName() {
        let n = 'estimates'
        return n
    }

}
