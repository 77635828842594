import {Component} from "react";
import {Navigate} from 'react-router-dom';
export default class Button extends Component {

    constructor(props) {
        super(props);
        this.state = {
            to : null
        }
    }

    render() {
        const {props} = this;
        return <button className="btn" onClick={this.onClick}>{props.text?props.text:props.children}{this.state.to && <Navigate to={this.state.to} push={true} />}</button>
    }

    onClick = () => {
        if (typeof this.props.click === "function") return this.props.click()
        if (this.props.to) {
            this.setState({to: this.props.to})
        }
    }

}
