import {Component} from "react";
import Panel from "../ui/Panel";
import Txt from "../ui/Txt";
import Button from "./Button";
import Container from "./Container";
import FormInput from "./FormInput";

export default class Wizard extends Component {

    constructor() {
        super();
        this.state = {
            questionIndex : 0,
            answers : [],
            values : {},
            offset : 0
        }
    }

    componentDidMount() {
        const offset = this.main.getBoundingClientRect().height - this.inner.getBoundingClientRect().height
        this.main.style.height = (this.inner.getBoundingClientRect().height + offset) + 'px'
        this.setState({offset})
    }

    render() {
        return <div className={this.clsName} ref={el => this.main = el}>
            <div className="wizard-inner" ref={el => this.inner = el}>
                <Container size="small">
                        {this.question && <Panel centered={true}>
                            <Txt title={true}>{this.question.label}</Txt>
                            <Txt paragraph={true}>{this.question.description}</Txt>
                            {this.question.options && this.question.options.map((option, k) => <div key={k}>
                                <Button click={() => this.answerQuestion(option.value)}>{option.label}</Button>
                            </div>)}
                            {this.question.fields &&
                                <Panel centered={true}>
                                    {this.question.fields.map((field, k) => <FormInput key={k} type="number" placeholder={field.label} onChange={value => this.updateField(field.name, value)} />)}
                                    <Button click={() => this.answerQuestion()}>Submit</Button>
                                </Panel>}
                        </Panel>}
                </Container>
                </div>
            </div>
    }

    updateField(name, value) {
        this.setState({values: {...this.state.values, [name] : value }})
    }

    answerQuestion(value) {
        let {questionIndex} = this.state;

        questionIndex += 1
        this.setState({answers : [...this.state.answers, value], questionIndex})
        if (this.question.name) this.setState({values : {...this.state.values, [this.question.name] : value}})


        if (this.props.questions.length === questionIndex) {
            this.submitAnswers()
        }
        else setTimeout(() => {
            this.main.style.height = (this.inner.getBoundingClientRect().height + this.state.offset) + 'px'
        },1)
    }

    submitAnswers() {
        this.props.onSubmit()
    }

    get question() {
        return this.props.questions && this.props.questions[this.state.questionIndex] ? this.props.questions[this.state.questionIndex] : null
    }

    get finished() {
        return this.props.questions.length <= this.state.questionIndex
    }

    get clsName() {
        return 'wizard'
    }
}
