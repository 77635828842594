import {Component} from "react";
import Button from "../../ui/Button";
import Panel from "../../ui/Panel";
import Container from "../../ui/Container";
import Txt from "../../ui/Txt";
import ThreeModel from "../../presets/ThreeModel";
import {Euler, Raycaster, Vector2} from "three";

export default class Welcome extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rot: -0.2,
            animationSpeed: 2,
            target : null,
            startX : null,
            lastX : null
        }
    }


    render() {
        return <div className={this.clsName}>
            <Container size="small" vCentered={true}>
                <ThreeModel path="./novulean.gltf" initScene={this.initScene} width="100%" animate={this.animate} onDown={this.onDown} onMove={this.onMove} onUp={this.onUp} />
                <Panel centered={true}>
                    <Txt title={true}>Welcome to NovuLean</Txt>
                    <Txt big={true}>Your personalized nutrition plan, from <Txt bold={true}>Novuskin</Txt>.</Txt>
                    <Txt paragraph={true}>Ready to see how your optimized plan looks like?</Txt>
                    <Button to="/quiz">Start Quiz</Button>
                </Panel>
                <Panel raw={true} centered={true}>
                    <Txt>Already have an account? <Txt to="/login">Sign In</Txt></Txt>
                </Panel>
            </Container>
        </div>
    }

    initScene = () => {

    }

    onDown = (e, scene, camera, renderer, lights) => {
        const {clientX, clientY} = e.targetTouches ? e.targetTouches[0] : e, pointer = new Vector2()
        const rect = renderer.domElement.getBoundingClientRect();
        pointer.x = (( clientX - rect.left ) / ( rect.right - rect.left ) ) * 2 - 1;
        pointer.y = - (( clientY - rect.top ) / ( rect.bottom - rect.top) ) * 2 + 1;

        const raycaster = new Raycaster()
        let intersects = []
        raycaster.setFromCamera( pointer, camera );
        intersects = raycaster.intersectObjects( scene.children ); // direct hit
        if (!intersects.length) { // try a few rays around
            let rad, x,y
            for (let i = 0; i < 18; i++) {
                rad = -Math.PI/2 + (i*20/360)*Math.PI
                x = Math.cos(rad); y = Math.sin(rad)
                // distance runs
                for (let i = 3; i <= 24; i += 3) {
                    pointer.x = (( (x*i)+clientX - rect.left ) / ( rect.right - rect.left ) ) * 2 - 1;
                    pointer.y = - (( (y*i)+clientY - rect.top ) / ( rect.bottom - rect.top) ) * 2 + 1;
                    raycaster.setFromCamera( pointer, camera );
                    intersects = [...intersects, ...raycaster.intersectObjects( scene.children )]
                if (intersects.length) break
                }
            }
        }

        if (intersects.length > 0) {
            this.setState({target : intersects[0].object, startX : clientX})
            intersects[0].object.held = true
        }
    }
    onMove = (e, scene, camera, renderer, lights) => {
        const {target, startX} = this.state
        const {clientX} = e.targetTouches ? e.targetTouches[0] : e
        let diff
        if (target) {
            diff = startX - clientX
            if (target.rotation.x >= Math.PI) target.rotation.y -= Math.PI
            if (target.rotation.x <= -Math.PI) target.rotation.y += Math.PI
            const ex = (target.ex ? target.ex : target.rotation.y) + diff*0.005,
                eu = new Euler(0, ex, 0);

            target.setRotationFromEuler(eu)
            target.ex = ex
        }
        this.setState({lastX: clientX, startX: (clientX + startX)/2})
    }
    onUp = (e) => {
        const {target, startX, lastX} = this.state

        if (target) {
            const diff = startX - lastX
            target.power = diff*0.005
        }
        this.setState({target: null, startX: null})
    }

    animate = (scene, camera, renderer, lights) => {
        let rot = this.state.rot + (0.003), eu

        this.setState({rot})
        const children = scene.children[0].children
        for (let child of children) {
            if (rot >= 0) {
                if (rot < 0.003) child.scale.set(1,1,1)
                eu = new Euler(0, rot*this.state.animationSpeed, 0)
                if (!child.held) child.setRotationFromEuler(eu)
                else {
                    if (child.power) {
                        // t = child.rotation.y + child.power
                        // if (t>Math.PI/2) t -= Math.PI/2
                        // else if (t<-Math.PI/2) t += Math.PI/2
                        eu = new Euler(0, child.rotation.y, 0)
                        child.rotateY(child.power)
                        // child.setRotationFromEuler(eu)
                        child.power *= 0.95
                    }
                }
                rot /= 2
            }
            else child.scale.set(1+rot/6,1+rot/6,1+rot/6)
        }

        const sin = Math.sin(this.state.rot*8)
        lights.forEach(light => light.intensity = 3.1 + sin*1.1)
    }

    get clsName() {
        return 'home'
    }
}
