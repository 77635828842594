import {Component} from "react";
import {Navigate} from 'react-router-dom';
import PropTypes from "prop-types";
export default class Box extends Component {

    constructor(props) {
        super(props);
        this.state = {
            to : null
        }
    }

    render() {
        const {props} = this;
        return <div className={this.clsName} onClick={this.onClick}>
            <div className="box-content">{props.children}</div>
            {this.state.to && <Navigate to={this.state.to} push={true} />}
        </div>
    }

    onClick = () => {
        if (this.props.click) return this.props.click()
        else if (this.props.to) {
            this.setState({to: this.props.to})
        }
    }

    get clsName() {
        let n = 'box'
        if (this.props.square) n += ' square'
        if (this.props.full) n += ' full'
        return n
    }
}

Box.propTypes = {
    to : PropTypes.string,
    click : PropTypes.func,
    full : PropTypes.bool,
    square: PropTypes.bool,
}
