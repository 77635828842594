import {Component} from "react";
import BoxGroup from "../ui/BoxGroup";
import Box from "../ui/Box";
import Panel from "../ui/Panel";
import Txt from "../ui/Txt";
export default class StatsBar extends Component {

    render() {
        return <div className={this.clsName}>
            <Panel raw={true}>

            <BoxGroup centered={true} square={true}>
                <Box>
                    <Txt big={true}>Gender</Txt>
                    <Txt title={true}>{this.gender}</Txt>
                </Box>
                <Box>
                    <Txt big={true}>Weight</Txt>
                    <Txt title={true}>{this.props.weight}kg</Txt>
                </Box>
                <Box>
                    <Txt big={true}>Age</Txt>
                    <Txt title={true}>{this.props.age}</Txt>
                </Box>
                <Box>
                    <Txt big={true}>Height</Txt>
                    <Txt title={true}>{this.props.height}cm</Txt>
                </Box>
            </BoxGroup>
            <BoxGroup primary={true} centered={true} square={true}>
                <Box>
                    <Txt big={true}>Number of meals a day</Txt>
                    <Txt title={true}>{this.props.dailyMeals}</Txt>
                </Box>
                <Box>
                    <Txt big={true}>Workouts per week</Txt>
                    <Txt title={true}>{this.props.trainings}</Txt>
                </Box>
                <Box>
                    <Txt big={true}>Recommended daily liters of water</Txt>
                    <Txt title={true}>{this.props.dailyWater}L</Txt>
                </Box>
                <Box>
                    <Txt big={true}>Sleep hours per night</Txt>
                    <Txt title={true}>{this.props.dailySleep} Hours</Txt>
                </Box>
            </BoxGroup>
            </Panel>
        </div>
    }

    get gender() {
        return this.props.gender.toLowerCase()[0] === 'm' ? 'Male' :
            (this.props.gender.toLowerCase()[0] === 'f' ? 'female':'')
    }

    get clsName() {
        let n = 'stats-bar'
        return n
    }

}
