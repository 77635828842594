import {Component} from "react";
import Container from "../../ui/Container";
import Panel from "../../ui/Panel";
import Txt from "../../ui/Txt";
import StatsBar from "../../presets/StatsBar";
import Estimates from "../../presets/Estimates";
import Button from "../../ui/Button";

export default class Results extends Component {

    render() {
        return <Container>
            <Panel raw={true} centered={true}>
                <Txt title={true}>Your personalized nutrition plan is ready!</Txt>
            </Panel>
            <Panel raw={true} horizontal={true} full={true}>
                <StatsBar gender={'m'} age={37} weight={66} height={171} dailyMeals={5} trainings={3} dailyWater={1.4} dailySleep={7} />
            </Panel>
            <Panel full={true} centered={true} raw={true}>
            <Button to="/signup">Sign Up Now</Button>

            </Panel>
            <Panel raw={true} full={true}>
                <Estimates calories={1850} weight={63} hipSize={2} />
            </Panel>
            <Panel full={true} centered={true} raw={true}>
                <Button to="/signup">Sign Up Now</Button>
            </Panel>
        </Container>
    }

    get clsName() {
        return 'home'
    }
}
