import {Component} from "react";
import {Scene, PerspectiveCamera, WebGLRenderer, PointLight} from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';


export default class ThreeModel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            model : null,
            rot : 0,
            axis: 'y',
            width: 330,
            height: 380,
            cameraDistance: 3,
            speed: 1
        }
        this.running = false

    }

    render() {
        return <div className={this.clsName} ref={ref => (this.mount = ref)}>
        </div>
    }

    onUp = (e) => (this.props.onUp?this.props.onUp(e,this.scene,this.camera,this.renderer,this.lights):()=>{})
    onMove = (e) => (this.props.onMove?this.props.onMove(e,this.scene,this.camera,this.renderer,this.lights):()=>{})
    onDown = (e) => (this.props.onDown?this.props.onDown(e,this.scene,this.camera,this.renderer,this.lights):()=>{})

    componentDidMount() {
        const loader = new GLTFLoader()

        let {width, height} = this.props
        if (!width) width = this.state.width
        if (!height) height = this.state.height
        if (typeof width === 'string' && width.indexOf('%')>=0) width = (width.replace(/%/g,'')/100)*this.mount.getBoundingClientRect().width
        if (typeof height === 'string' && height.indexOf('%')>=0) height = (height.replace(/%/g,'')/100)*this.mount.getBoundingClientRect().height

        this.renderer = new WebGLRenderer({alpha: true, antialias: true})
        this.renderer.setSize(width, height);
        this.renderer.setPixelRatio( window.devicePixelRatio );
        this.mount.appendChild(this.renderer.domElement)

        if (window.innerHeight < 660 || window.innerWidth < 560) {
            height *= 0.7; width *= 0.7; this.renderer.setSize(width,height)
        }

        this.setState({
            width: width,
            height: height,
            speed: this.props.speed || this.state.speed,
            axis: this.props.axis || this.state.axis,
            cameraDistance: this.props.cameraDistance || this.state.cameraDistance,
        })
        this.running = true
        loader.load(this.props.path, (gltf) => this.initScene(gltf))

        this.renderer.domElement.addEventListener('ontouchstart' in window?'touchstart':'mousedown', this.onDown)
        this.renderer.domElement.addEventListener('ontouchstart' in window?'touchmove':'mousemove', this.onMove)
        this.renderer.domElement.addEventListener('ontouchstart' in window?'touchend':'mouseup', this.onUp)
        this.renderer.domElement.addEventListener('ontouchstart' in window?'touchcancel':'mouseout', this.onUp)

        console.log("Mtd.")
    }

    componentWillUnmount() {
        console.log("Un-mtd.")
        for (let child of this.scene.children) {
            if (child.dispose) child.dispose()
        }
        this.running = false

        this.renderer.domElement.removeEventListener('mousedown', this.onDown)
        this.renderer.domElement.removeEventListener('touchstart', this.onDown)
        this.renderer.domElement.removeEventListener('mousemove', this.onMove)
        this.renderer.domElement.removeEventListener('touchmove', this.onMove)
        this.renderer.domElement.removeEventListener('mouseup', this.onUp)
        this.renderer.domElement.removeEventListener('touchend', this.onUp)
    }

    initScene = (gltf) => {
        const {width, height, cameraDistance} = this.state
        this.setState({model: gltf.scene})
        const scene = new Scene()
        scene.add(gltf.scene)
        const camera = new PerspectiveCamera(75, width/height, 0.1, 1000)

        camera.translateZ(cameraDistance)

        const light = new PointLight('#e2b29a',3, 100, 1)
        light.translateX(1)
        light.translateZ(15)
        light.translateY(15)
        scene.add(light)

        const light2 = new PointLight('#e2b29a',3, 100, 1)
        light2.translateX(1)
        light2.translateZ(15)
        light2.translateY(-15)
        scene.add(light2)

        this.scene = scene;
        this.camera = camera;
        this.lights = [light, light2]

        if (this.props.initScene) this.props.initScene(this.scene, this.camera, this.renderer, this.lights)

        this.animate()
    }

    animate = () => {
        if (this.running) requestAnimationFrame( this.animate );
        if (this.props.animate) this.props.animate(this.scene, this.camera, this.renderer, this.lights)
        this.renderer.render( this.scene, this.camera );
    }

    get clsName() {
        return 'three-model'
    }
}
