import {Component} from "react";
import PropTypes from "prop-types";
import FormInput from "./FormInput";
import Button from "./Button";
export default class Form extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data : {}
        }
    }

    render() {
        return <div className={this.clsName}>
            {this.props.fields.map((field, k) => <FormInput key={k} placeholder={field.label} type={this.getType(field)} onChange={value => this.updateField(field.name, value)} />)}
            <Button click={() => this.submitForm()}>{this.submitText}</Button>
        </div>
    }

    getType = (field) => {
        switch (field.type) {
            case 'password': return 'password'
            case 'number': return 'number'
            default: return 'text'
        }
    }

    updateField = (name, value) => {
        this.setState({data : {...this.state.data, [name]: value }})
    }

    submitForm = () => {
        if (this.props.submit) this.props.submit(this.state.data)
    }

    get submitText() {
        return this.props.submitText || 'Submit'
    }

    get clsName() {
        let n = 'form'
        if (this.props.centered) n += ' centered'
        return n
    }

}

Form.propTypes = {
    submitText: PropTypes.string,
    submit : PropTypes.func,
    fields : PropTypes.array,
    centered : PropTypes.bool,
}
