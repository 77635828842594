import {Component} from "react";
import Panel from "../ui/Panel";
import Box from "../ui/Box";
import Txt from "../ui/Txt";

export default class DailyMenu extends Component {

    render() {
        return <Panel raw={true} centered={true}>
            {[1,2,3,4,5].map((it,i) => <Box key={it} full={true}>
                <Txt big={true}>{this.mealName(i)}</Txt>
            </Box>)}
            </Panel>
    }

    mealName(index) {
        switch (index) {
            case 0: return 'Breakfast'
            case 1: return 'Snack 1'
            case 2: return 'Lunch'
            case 3: return 'Snack 2'
            case 4: return 'Dinner'
        }
    }

}
