import {Component} from "react";
export default class Image extends Component {

    render() {
        return <img src={this.img} alt={this.alt} />
    }

    get img() {
        return this.props.img || null
    }

    get alt() {
        return this.props.alt || ''
    }

}
