import Navbar from "./components/ui/Navbar";
import {Route, Routes} from "react-router-dom";

import "./scss/style.scss";
import Welcome from "./components/views/landing/Welcome";
import Quiz from "./components/views/landing/Quiz";
import Results from "./components/views/landing/Results";
import Signup from "./components/views/Signup";
import Login from "./components/views/Login";
import MyAccount from "./components/views/plan/MyAccount";
import NutritionPlan from "./components/views/plan/NutritionPlan";

function App() {
  return (
    <div className="App">
        <Navbar />
        <Routes>
            <Route path="/" element={<Welcome />} />
            <Route path="/quiz" element={<Quiz />} />
            <Route path="/quiz/results" element={<Results />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/plan" element={<NutritionPlan />} />
            <Route path="/plan/account" element={<MyAccount />} />
            <Route path="/plan/menu" element={<NutritionPlan />} />
        </Routes>
    </div>
  );
}

export default App;
