import {Component} from "react";
import PropTypes from "prop-types";
export default class Panel extends Component {

    render() {
        return <div className={this.clsName}>
            {this.props.children}
        </div>
    }

    get clsName() {
        let n = 'panel'
        if (this.props.centered) n += ' centered'
        if (this.props.horizontal) n += ' horizontal'
        if (this.props.full) n += ' full'
        if (this.props.raw) n += ' raw'
        return n
    }

}

Panel.propTypes = {
    centered: PropTypes.bool,
    horizontal: PropTypes.bool,
    full: PropTypes.bool,
    raw: PropTypes.bool,
}
