import {Component} from "react";
import Form from "../ui/Form";
import Container from "../ui/Container";
import Panel from "../ui/Panel";
import Txt from "../ui/Txt";

const fields = [
    {name: "email", label: "E-mail"},
    {name: "password", label: "Password", type: "password"},
]

export default class Login extends Component {

    render() {
        return <Container vCentered={true}>
            <Panel centered={true}>
                <Txt title={true}>LOGIN</Txt>
                <Form fields={fields} submitText="Log In" submit={this.onSubmit} centered={true} />
            </Panel>
        </Container>
    }

    onSubmit = (data) => {
        console.log({data})
    }

}
