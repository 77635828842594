import {Component} from "react";
import Container from "../../ui/Container";
import Panel from "../../ui/Panel";
import Txt from "../../ui/Txt";

export default class MyAccount extends Component {

    render() {
        return <Container>
            <Panel raw={true} centered={true}>
                <Txt title={true}>My Account</Txt>
            </Panel>
        </Container>
    }

}
