import {Component} from "react";
import { Link } from 'react-router-dom'
import PropTypes from "prop-types";
import Panel from "./Panel";

export default class Txt extends Component {

    render() {
        const Tag = this.tag
        return <Tag to={this.props.to}>{this.props.children}</Tag>
    }

    get tag() {
        if (this.props.paragraph) return 'p'
        if (this.props.to) return Link
        if (this.props.title) return `h2`
        if (this.props.big) return `h3`
        if (this.props.bold) return 'b'
        return 'span'
    }

    get text() {
        if (typeof this.props.children !== 'object') return this.props.children
        return this.props.children.toString()
    }

}

Txt.propTypes = {
    title: PropTypes.bool,
    small: PropTypes.bool,
    big: PropTypes.bool,
    bold: PropTypes.bool,
    paragraph: PropTypes.bool,
    to: PropTypes.string,
}
