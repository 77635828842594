import {Component} from "react";
import Container from "../../ui/Container";
import Panel from "../../ui/Panel";
import Txt from "../../ui/Txt";
import DailyMenu from "../../presets/DailyMenu";

export default class NutritionPlan extends Component {

    render() {
        return <Container>
            <Panel raw={true} centered={true}>
                <Txt title={true}>My Personalized Nutrition Plan</Txt>
            </Panel>
            <Panel centered={true}>
                <Txt title={true}>Daily Menu</Txt>
                <Txt paragraph={true}>Welcome to your personalized daily menu, created for your nutrition profile. Every aspect of it can be personalized. Give it a try!</Txt>

                <DailyMenu />
            </Panel>
        </Container>
    }

}
